import '@assets/styles/main.css'

import { DefaultSeo, NextSeo } from 'next-seo'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import type { FC, ReactElement, ReactNode } from 'react'
import { useEffect } from 'react'

import seoConfig, { shouldUseDefaultConfig } from '@assets/config/seo'
import { ManagedUIContext } from '@components/ui/context'
import IsAnonymousProvider from '@context/is-anonymous'
import { ManagedStoreContext } from '@context/store'
import { motifConsole } from '@lib/utils/console'

const Noop: FC<{ children: ReactNode }> = ({ children }) => <>{children}</>

if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
  window.console = motifConsole(window.console)
}

export default function App({ Component, pageProps }: AppProps): ReactElement {
  const router = useRouter()
  const Comp = Component as any
  const isPublicHome = !!Comp.isPublicHome
  const isPublicPlayground = !!Comp.isPublicPlayground
  const isAnonymous = Comp.isAnonymous || isPublicHome || isPublicPlayground
  const Layout = Comp.Layout || Noop
  const getTemplate = Comp.getTemplate ?? ((page: any) => page)

  useEffect(() => {
    document.body.classList?.remove('loading')
  }, [])

  if ((Comp.isPublic || Comp.isPublicHome) && !isPublicPlayground) {
    // For public pages except public home landing page and playground,
    // return here as we don't want to wrap these in the full content.
    // We previously did not include `isPublicHome` here, and let it
    // pass on to the main app. This was in order to be able to shim
    // the Motif editor on the landing page. We've removed the editor
    // from the landing page due to repeatedly experiencing bugs
    // (Replicache, ES module loading) so we can now return here. Another
    // issue that it introduced was SEO: the landing page was not
    // catching the SEO tags, such as title and description, which might
    // have affected our Google ranking.
    return getTemplate(<Component {...pageProps} />, pageProps)
  }

  return (
    <>
      <Head>
        <meta
          key="viewport"
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        />
      </Head>

      {shouldUseDefaultConfig(router.asPath) && (
        <>
          <DefaultSeo {...seoConfig} />
          <NextSeo
            openGraph={{
              ...seoConfig.openGraph,
              url: `https://motif.land${router.asPath}`,
            }}
          />
        </>
      )}

      <IsAnonymousProvider.Provider
        initialState={{ isAnonymous: !!isAnonymous }}
      >
        <ManagedUIContext>
          <ManagedStoreContext>
            <Layout
              pageProps={pageProps}
              isAnonymous={isAnonymous}
              // For the Motif public home landing page, we do not
              // use file routes when navigating between tabs.
              useFileRoutes={!isPublicHome && !isPublicPlayground}
            >
              {getTemplate(<Component {...pageProps} />, pageProps)}
            </Layout>
          </ManagedStoreContext>
        </ManagedUIContext>
      </IsAnonymousProvider.Provider>
    </>
  )
}
