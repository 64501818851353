const getKey = (key: string, anonymous: boolean | undefined) => {
  return `${anonymous ? 'anonymous-' : ''}${key}`
}

export const getStoredPref = <T>(
  key: string,
  defaultValue: T,
  anonymous?: boolean
): T => {
  if (typeof localStorage === 'undefined') {
    return defaultValue
  }

  const value = localStorage.getItem(getKey(key, anonymous))
  if (value) {
    try {
      return JSON.parse(value)
    } catch {
      // Do nothing
    }
  }
  return defaultValue
}

export const setStoredPref = (
  key: string,
  value: any,
  anonymous: boolean
): void => {
  if (typeof localStorage === 'undefined') {
    return
  }

  localStorage.setItem(getKey(key, anonymous), value)
}

export const removeStoredPref = (key: string, anonymous: boolean): void => {
  if (typeof localStorage === 'undefined') {
    return
  }
  localStorage.removeItem(getKey(key, anonymous))
}
