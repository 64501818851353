import type { ReactNode, PropsWithChildren } from 'react'
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react'

import IsAnonymousProvider from '@context/is-anonymous'
import { getStoredPref, setStoredPref } from '@lib/localstorage'

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {}

export type MODAL_VIEWS =
  | 'SIGNUP_VIEW'
  | 'LOGIN_VIEW'
  | 'ADD_PROJECT_VIEW'
  | 'ADD_PROJECT_SELECT_TEMPLATE_VIEW'
  | 'ADD_SPACE_VIEW'
  | 'LEAVE_SPACE_VIEW'
  | 'DELETE_SPACE_VIEW'
  | 'DELETE_PROJECT_VIEW'
  | 'DELETE_ACCOUNT_VIEW'
  | 'ACCOUNT_SETTINGS_VIEW'
  | 'INVITATIONS_VIEW'
  | 'ADD_CUSTOM_DOMAIN'
  | 'CUSTOM_DOMAIN_GUIDE'
  | 'CUSTOM_DOMAIN_VERIFICATION_GUIDE'
  | 'CREATE_TOKEN'
  | 'NEW_TOKEN'
  | 'SEARCH_VIEW'
  | 'MOVE_PROJECT_VIEW'
  | 'CREATE_TEMPLATE'
  | 'MODULE_TAG_INPUT'
  | 'LIBRARY_VIEW'
  | 'PUBLISH_MODULE_DETAILS_VIEW'
  | 'HANDLE_INPUT'
  | 'MODULE_NAME_INPUT'
  | 'PAYMENT_FORM'
  | 'ADD_SNAPSHOT'
  | 'LOGIN_MODAL'
  | 'ALERT_VIEW'

export type ModalAction = {
  label: string
  onClick: () => void
}

export type ModalViewProps = {
  closeOthers?: boolean
  top?: boolean
  noPadding?: boolean
  title?: string
  subtitle?: string | ReactNode
  cancelButtonTitle?: string
  submitAction?: ModalAction
  destructiveAction?: ModalAction
  onSubmit?: (args?: any) => void
  allowOverflow?: boolean
  noCancel?: boolean
  extras?: any
}

export type DOCUMENT_BAR_TABS = 'DESIGN' | 'HISTORY'

type CONTENT_VIEWS = 'FILE_VIEW'

type ToastText = string

export interface State {
  closeEditor: () => void
  closeHistory: () => void
  closeModal: () => void
  closePreview: () => void
  contentView: string
  disableResponsivePreview: () => void
  displayFourthModal: boolean
  displayFirstModal: boolean
  displaySecondModal: boolean
  displayThirdModal: boolean
  modalView: { view: string; className: string; props: any } | undefined
  secondModalView: { view: string; className: string; props: any } | undefined
  thirdModalView: { view: string; className: string; props: any } | undefined
  fourthModalView: { view: string; className: string; props: any } | undefined
  displayToast: boolean
  documentBarTab: string
  enableResponsivePreview: () => void
  isAnonymous: boolean
  isAutoFormatMDXEnabled: boolean
  isBarsVisible: boolean
  isDocumentBarVisible: boolean
  isPageHistoryBarVisible: boolean
  isEditorVisible: boolean
  isFullScreenEditor: boolean
  isFullScreenPreview: boolean
  isHistoryVisible: boolean
  isPreviewVisible: boolean
  isRenderImmediately: boolean
  isResponsivePreview: boolean
  isSidebarVisible: boolean
  openDocumentBar: () => void
  openPageHistoryBar: () => void
  openEditor: () => void
  openHistory: () => void
  openModal: (closeOthers?: boolean) => void
  openPreview: () => void
  setDocumentBarTab: (tab: DOCUMENT_BAR_TABS) => void
  setModalView: (
    view: MODAL_VIEWS,
    className: string,
    fill?: boolean,
    props?: ModalViewProps,
    customView?: any,
    style?: any
  ) => void
  toastText: string
  toggleAutoFormatMDX: () => void
  toggleDocumentBar: () => void
  togglePageHistoryBar: () => void
  toggleFullScreenEditor: () => void
  toggleFullScreenPreview: () => void
  toggleRenderImmediately: () => void
  closeSidebar: () => void
  toggleSidebar: () => void
  userAvatar: string
}

const initialState = {
  closeEditor: noop,
  closeHistory: noop,
  closeModal: noop,
  closePreview: noop,
  contentView: 'FILE_VIEW',
  disableResponsivePreview: noop,
  displayFourthModal: false,
  displayFirstModal: false,
  displaySecondModal: false,
  displayThirdModal: false,
  displayToast: false,
  documentBarTab: 'DESIGN',
  enableResponsivePreview: noop,
  isAnonymous: false,
  isAutoFormatMDXEnabled: false,
  isBarsVisible: true,
  isDocumentBarVisible: false,
  isPageHistoryBarVisible: false,
  isEditorVisible: true,
  isFullScreenEditor: false,
  isFullScreenPreview: false,
  isHistoryVisible: false,
  isPreviewVisible: true,
  isRenderImmediately: true,
  isResponsivePreview: false,
  isSidebarVisible: true,
  modalView: undefined,
  secondModalView: undefined,
  thirdModalView: undefined,
  fourthModalView: undefined,
  openDocumentBar: noop,
  openPageHistoryBar: noop,
  openEditor: noop,
  openHistory: noop,
  openModal: noop,
  openPreview: noop,
  setDocumentBarTab: noop,
  setModalView: noop,
  toastText: '',
  toggleAutoFormatMDX: noop,
  toggleDocumentBar: noop,
  togglePageHistoryBar: noop,
  toggleFullScreenEditor: noop,
  toggleFullScreenPreview: noop,
  toggleRenderImmediately: noop,
  closeSidebar: noop,
  toggleSidebar: noop,
  userAvatar: '',
}

type Action =
  | {
      type: 'OPEN_SIDEBAR'
    }
  | {
      type: 'CLOSE_SIDEBAR'
    }
  | {
      type: 'TOGGLE_SIDEBAR'
    }
  | {
      type: 'ACTIVATE_FULL_SCREEN_EDITOR'
    }
  | {
      type: 'DEACTIVATE_FULL_SCREEN_EDITOR'
    }
  | {
      type: 'TOGGLE_FULL_SCREEN_EDITOR'
    }
  | {
      type: 'ACTIVATE_FULL_SCREEN_PREVIEW'
    }
  | {
      type: 'DEACTIVATE_FULL_SCREEN_PREVIEW'
    }
  | {
      type: 'TOGGLE_FULL_SCREEN_PREVIEW'
    }
  | {
      type: 'OPEN_DOCUMENT_BAR'
    }
  | {
      type: 'CLOSE_DOCUMENT_BAR'
    }
  | {
      type: 'TOGGLE_DOCUMENT_BAR'
    }
  | {
      type: 'OPEN_PAGE_HISTORY_BAR'
    }
  | {
      type: 'CLOSE_PAGE_HISTORY_BAR'
    }
  | {
      type: 'TOGGLE_PAGE_HISTORY_BAR'
    }
  | {
      type: 'OPEN_HISTORY'
    }
  | {
      type: 'CLOSE_HISTORY'
    }
  | {
      type: 'OPEN_EDITOR'
    }
  | {
      type: 'CLOSE_EDITOR'
    }
  | {
      type: 'TOGGLE_EDITOR'
    }
  | {
      type: 'OPEN_PREVIEW'
    }
  | {
      type: 'CLOSE_PREVIEW'
    }
  | {
      type: 'TOGGLE_PREVIEW'
    }
  | {
      type: 'ENABLE_RESPONSIVE_PREVIEW'
    }
  | {
      type: 'DISABLE_RESPONSIVE_PREVIEW'
    }
  | {
      type: 'ENABLE_RENDER_IMMEDIATELY'
    }
  | {
      type: 'DISABLE_RENDER_IMMEDIATELY'
    }
  | {
      type: 'TOGGLE_RENDER_IMMEDIATELY'
    }
  | {
      type: 'ENABLE_AUTO_FORMAT_MDX'
    }
  | {
      type: 'DISABLE_AUTO_FORMAT_MDX'
    }
  | {
      type: 'TOGGLE_AUTO_FORMAT_MDX'
    }
  | {
      type: 'SHOW_BARS'
    }
  | {
      type: 'HIDE_BARS'
    }
  | {
      type: 'OPEN_TOAST'
    }
  | {
      type: 'CLOSE_TOAST'
    }
  | {
      type: 'SET_TOAST_TEXT'
      text: ToastText
    }
  | {
      type: 'OPEN_MODAL'
      closeOthers?: boolean
    }
  | {
      type: 'CLOSE_MODAL'
    }
  | {
      type: 'SET_MODAL_VIEW'
      modalView:
        | {
            view: MODAL_VIEWS
            className: string
            fill: boolean
            props?: ModalViewProps
            customView?: any
            style?: any
          }
        | undefined
    }
  | {
      type: 'SET_CONTENT_VIEW'
      view: CONTENT_VIEWS
    }
  | {
      type: 'SET_USER_AVATAR'
      value: string
    }
  | {
      type: 'SET_DOCUMENT_BAR_TAB'
      tab: DOCUMENT_BAR_TABS
    }

export const UIContext = createContext<State>(initialState)

UIContext.displayName = 'UIContext'

type RightSidebarType = 'DOCUMENT' | 'PAGE_HISTORY'

const setRightSidebarBarOpen = (
  sidebar: RightSidebarType,
  open: boolean,
  isAnonymous: boolean
) => {
  const getPrefKey = (sidebar: RightSidebarType) => {
    switch (sidebar) {
      case 'DOCUMENT':
        return 'is_document_bar_visible'
      case 'PAGE_HISTORY':
        return 'is_page_history_bar_visible'
    }
  }

  const getOtherTypes = (sidebar: RightSidebarType): RightSidebarType[] => {
    switch (sidebar) {
      case 'DOCUMENT':
        return ['PAGE_HISTORY']
      case 'PAGE_HISTORY':
        return ['DOCUMENT']
    }
  }

  setStoredPref(getPrefKey(sidebar), JSON.stringify(open), isAnonymous)

  if (open) {
    getOtherTypes(sidebar).forEach((otherSidebar) => {
      setRightSidebarBarOpen(otherSidebar, false, isAnonymous)
    })
  }

  switch (sidebar) {
    case 'DOCUMENT':
      return { isDocumentBarVisible: open }
    case 'PAGE_HISTORY':
      return { isPageHistoryBarVisible: open }
  }
}

const uiReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'OPEN_SIDEBAR': {
      setStoredPref(
        'is_sidebar_visible',
        JSON.stringify(true),
        state.isAnonymous
      )
      return {
        ...state,
        isSidebarVisible: true,
      }
    }
    case 'CLOSE_SIDEBAR': {
      setStoredPref(
        'is_sidebar_visible',
        JSON.stringify(false),
        state.isAnonymous
      )
      return {
        ...state,
        isSidebarVisible: false,
      }
    }
    case 'TOGGLE_SIDEBAR': {
      const newIsSidebarVisible = !state.isSidebarVisible
      setStoredPref(
        'is_sidebar_visible',
        JSON.stringify(newIsSidebarVisible),
        state.isAnonymous
      )
      return {
        ...state,
        isSidebarVisible: newIsSidebarVisible,
      }
    }
    case 'ACTIVATE_FULL_SCREEN_EDITOR': {
      return {
        ...state,
        isFullScreenEditor: true,
        isFullScreenPreview: false,
      }
    }
    case 'DEACTIVATE_FULL_SCREEN_EDITOR': {
      return {
        ...state,
        isFullScreenEditor: false,
      }
    }
    case 'TOGGLE_FULL_SCREEN_EDITOR': {
      return {
        ...state,
        isFullScreenEditor: !state.isFullScreenEditor,
      }
    }
    case 'ACTIVATE_FULL_SCREEN_PREVIEW': {
      return {
        ...state,
        isFullScreenEditor: false,
        isFullScreenPreview: true,
      }
    }
    case 'DEACTIVATE_FULL_SCREEN_PREVIEW': {
      return {
        ...state,
        isFullScreenPreview: false,
      }
    }
    case 'TOGGLE_FULL_SCREEN_PREVIEW': {
      return {
        ...state,
        isFullScreenPreview: !state.isFullScreenPreview,
      }
    }
    case 'OPEN_DOCUMENT_BAR': {
      return {
        ...state,
        ...setRightSidebarBarOpen('DOCUMENT', true, state.isAnonymous),
      }
    }
    case 'CLOSE_DOCUMENT_BAR': {
      return {
        ...state,
        ...setRightSidebarBarOpen('DOCUMENT', false, state.isAnonymous),
      }
    }
    case 'TOGGLE_DOCUMENT_BAR': {
      return {
        ...state,
        ...setRightSidebarBarOpen(
          'DOCUMENT',
          !state.isDocumentBarVisible,
          state.isAnonymous
        ),
      }
    }
    case 'OPEN_PAGE_HISTORY_BAR': {
      return {
        ...state,
        ...setRightSidebarBarOpen('PAGE_HISTORY', true, state.isAnonymous),
      }
    }
    case 'CLOSE_PAGE_HISTORY_BAR': {
      return {
        ...state,
        ...setRightSidebarBarOpen('PAGE_HISTORY', false, state.isAnonymous),
      }
    }
    case 'TOGGLE_PAGE_HISTORY_BAR': {
      return {
        ...state,
        ...setRightSidebarBarOpen(
          'PAGE_HISTORY',
          !state.isPageHistoryBarVisible,
          state.isAnonymous
        ),
      }
    }
    case 'OPEN_HISTORY': {
      setStoredPref(
        'is_history_visible',
        JSON.stringify(true),
        state.isAnonymous
      )
      return {
        ...state,
        isHistoryVisible: true,
      }
    }
    case 'CLOSE_HISTORY': {
      setStoredPref(
        'is_history_visible',
        JSON.stringify(false),
        state.isAnonymous
      )
      return {
        ...state,
        isHistoryVisible: false,
      }
    }
    case 'OPEN_EDITOR': {
      setStoredPref(
        'is_editor_visible',
        JSON.stringify(true),
        state.isAnonymous
      )
      return {
        ...state,
        isEditorVisible: true,
      }
    }
    case 'CLOSE_EDITOR': {
      setStoredPref(
        'is_editor_visible',
        JSON.stringify(false),
        state.isAnonymous
      )
      return {
        ...state,
        isEditorVisible: false,
      }
    }
    case 'TOGGLE_EDITOR': {
      const newIsEditorVisible = !state.isEditorVisible
      setStoredPref(
        'is_editor_visible',
        JSON.stringify(newIsEditorVisible),
        state.isAnonymous
      )
      return {
        ...state,
        isEditorVisible: newIsEditorVisible,
      }
    }
    case 'OPEN_PREVIEW': {
      setStoredPref(
        'is_preview_visible',
        JSON.stringify(true),
        state.isAnonymous
      )
      return {
        ...state,
        isPreviewVisible: true,
      }
    }
    case 'CLOSE_PREVIEW': {
      setStoredPref(
        'is_preview_visible',
        JSON.stringify(false),
        state.isAnonymous
      )
      return {
        ...state,
        isPreviewVisible: false,
      }
    }
    case 'TOGGLE_PREVIEW': {
      const newIsPreviewVisible = !state.isPreviewVisible
      setStoredPref(
        'is_preview_visible',
        JSON.stringify(newIsPreviewVisible),
        state.isAnonymous
      )
      return {
        ...state,
        isPreviewVisible: newIsPreviewVisible,
      }
    }
    case 'ENABLE_RESPONSIVE_PREVIEW': {
      setStoredPref(
        'is_responsive_preview',
        JSON.stringify(true),
        state.isAnonymous
      )
      return {
        ...state,
        isResponsivePreview: true,
      }
    }
    case 'DISABLE_RESPONSIVE_PREVIEW': {
      setStoredPref(
        'is_responsive_preview',
        JSON.stringify(false),
        state.isAnonymous
      )
      return {
        ...state,
        isResponsivePreview: false,
      }
    }
    case 'ENABLE_RENDER_IMMEDIATELY': {
      setStoredPref(
        'is_render_immediately',
        JSON.stringify(true),
        state.isAnonymous
      )
      return {
        ...state,
        isRenderImmediately: true,
      }
    }
    case 'DISABLE_RENDER_IMMEDIATELY': {
      setStoredPref(
        'is_render_immediately',
        JSON.stringify(false),
        state.isAnonymous
      )
      return {
        ...state,
        isRenderImmediately: false,
      }
    }
    case 'TOGGLE_RENDER_IMMEDIATELY': {
      setStoredPref(
        'is_render_immediately',
        JSON.stringify(!state.isRenderImmediately),
        state.isAnonymous
      )
      return {
        ...state,
        isRenderImmediately: !state.isRenderImmediately,
      }
    }
    case 'ENABLE_AUTO_FORMAT_MDX': {
      setStoredPref(
        'is_auto_format_mdx',
        JSON.stringify(true),
        state.isAnonymous
      )
      return {
        ...state,
        isAutoFormatMDXEnabled: true,
      }
    }
    case 'DISABLE_AUTO_FORMAT_MDX': {
      setStoredPref(
        'is_auto_format_mdx',
        JSON.stringify(false),
        state.isAnonymous
      )
      return {
        ...state,
        isAutoFormatMDXEnabled: false,
      }
    }
    case 'TOGGLE_AUTO_FORMAT_MDX': {
      setStoredPref(
        'is_auto_format_mdx',
        JSON.stringify(!state.isAutoFormatMDXEnabled),
        state.isAnonymous
      )
      return {
        ...state,
        isAutoFormatMDXEnabled: !state.isAutoFormatMDXEnabled,
      }
    }
    case 'SHOW_BARS': {
      return {
        ...state,
        isBarsVisible: true,
      }
    }
    case 'HIDE_BARS': {
      return {
        ...state,
        isBarsVisible: false,
      }
    }
    case 'OPEN_MODAL': {
      if (action.closeOthers) {
        return {
          ...state,
          displayFirstModal: true,
          displaySecondModal: false,
          displayThirdModal: false,
          displayFourthModal: false,
        }
      } else if (!state.displayFirstModal) {
        return {
          ...state,
          displayFirstModal: true,
          displaySecondModal: false,
          displayThirdModal: false,
          displayFourthModal: false,
        }
      } else if (!state.displaySecondModal) {
        return {
          ...state,
          displaySecondModal: true,
          displayThirdModal: false,
          displayFourthModal: false,
        }
      } else if (!state.displayThirdModal) {
        return {
          ...state,
          displayThirdModal: true,
          displayFourthModal: false,
        }
      } else {
        return {
          ...state,
          displayFourthModal: true,
        }
      }
    }
    case 'CLOSE_MODAL': {
      if (state.displayFourthModal) {
        return {
          ...state,
          displayFourthModal: false,
        }
      } else if (state.displayThirdModal) {
        return {
          ...state,
          displayThirdModal: false,
        }
      } else if (state.displaySecondModal) {
        return {
          ...state,
          displaySecondModal: false,
        }
      }
      return {
        ...state,
        displayFirstModal: false,
      }
    }
    case 'OPEN_TOAST': {
      return {
        ...state,
        displayToast: true,
      }
    }
    case 'CLOSE_TOAST': {
      return {
        ...state,
        displayToast: false,
      }
    }
    case 'SET_MODAL_VIEW': {
      let key:
        | 'modalView'
        | 'secondModalView'
        | 'thirdModalView'
        | 'fourthModalView'

      if (action.modalView?.props?.closeOthers) {
        key = 'modalView'
      } else if (!state.displayFirstModal) {
        key = 'modalView'
      } else if (!state.displaySecondModal) {
        key = 'secondModalView'
      } else if (!state.displayThirdModal) {
        key = 'thirdModalView'
      } else {
        key = 'fourthModalView'
      }
      if (!action.modalView) {
        return state
      }
      return {
        ...state,
        [key]: {
          view: action.modalView.view,
          className: action.modalView.className,
          fill: action.modalView.fill,
          props: action.modalView.props,
          customView: action.modalView.customView,
          style: action.modalView.style,
        },
      }
    }
    case 'SET_CONTENT_VIEW': {
      return {
        ...state,
        contentView: action.view,
      }
    }
    case 'SET_TOAST_TEXT': {
      return {
        ...state,
        toastText: action.text,
      }
    }
    case 'SET_USER_AVATAR': {
      return {
        ...state,
        userAvatar: action.value,
      }
    }
    case 'SET_DOCUMENT_BAR_TAB': {
      setStoredPref('document_bar_tab', action.tab, state.isAnonymous)
      return {
        ...state,
        documentBarTab: action.tab,
      }
    }
  }
}

export const UIProvider = (props: PropsWithChildren) => {
  const { isAnonymous } = IsAnonymousProvider.useContainer()
  const [state, dispatch] = useReducer(uiReducer, {
    ...initialState,
    isAnonymous,
  })

  const openSidebar = () => dispatch({ type: 'OPEN_SIDEBAR' })
  const closeSidebar = () => dispatch({ type: 'CLOSE_SIDEBAR' })
  const toggleSidebar = () => dispatch({ type: 'TOGGLE_SIDEBAR' })

  const toggleFullScreenEditor = () =>
    dispatch({ type: 'TOGGLE_FULL_SCREEN_EDITOR' })
  const toggleFullScreenPreview = () =>
    dispatch({ type: 'TOGGLE_FULL_SCREEN_PREVIEW' })

  const openDocumentBar = () => dispatch({ type: 'OPEN_DOCUMENT_BAR' })
  const closeDocumentBar = () => dispatch({ type: 'CLOSE_DOCUMENT_BAR' })
  const toggleDocumentBar = () => dispatch({ type: 'TOGGLE_DOCUMENT_BAR' })

  const openPageHistoryBar = () => dispatch({ type: 'OPEN_PAGE_HISTORY_BAR' })
  const closePageHistoryBar = () => dispatch({ type: 'CLOSE_PAGE_HISTORY_BAR' })
  const togglePageHistoryBar = () =>
    dispatch({ type: 'TOGGLE_PAGE_HISTORY_BAR' })

  const openHistory = () => dispatch({ type: 'OPEN_HISTORY' })
  const closeHistory = () => dispatch({ type: 'CLOSE_HISTORY' })

  const openEditor = () => dispatch({ type: 'OPEN_EDITOR' })
  const closeEditor = () => dispatch({ type: 'CLOSE_EDITOR' })
  const toggleEditor = () => dispatch({ type: 'TOGGLE_EDITOR' })

  const openPreview = () => dispatch({ type: 'OPEN_PREVIEW' })
  const closePreview = () => dispatch({ type: 'CLOSE_PREVIEW' })
  const togglePreview = () => dispatch({ type: 'TOGGLE_PREVIEW' })

  const enableResponsivePreview = () =>
    dispatch({ type: 'ENABLE_RESPONSIVE_PREVIEW' })
  const disableResponsivePreview = () =>
    dispatch({ type: 'DISABLE_RESPONSIVE_PREVIEW' })

  const enableRenderImmediately = () =>
    dispatch({ type: 'ENABLE_RENDER_IMMEDIATELY' })
  const disableRenderImmediately = () =>
    dispatch({ type: 'DISABLE_RENDER_IMMEDIATELY' })
  const toggleRenderImmediately = () =>
    dispatch({ type: 'TOGGLE_RENDER_IMMEDIATELY' })

  const enableAutoFormatMDX = () => dispatch({ type: 'ENABLE_AUTO_FORMAT_MDX' })
  const disableAutoFormatMDX = () =>
    dispatch({ type: 'DISABLE_AUTO_FORMAT_MDX' })
  const toggleAutoFormatMDX = () => dispatch({ type: 'TOGGLE_AUTO_FORMAT_MDX' })

  const showBars = () => dispatch({ type: 'SHOW_BARS' })
  const hideBars = () => dispatch({ type: 'HIDE_BARS' })

  const openModal = (closeOthers?: boolean) =>
    dispatch({ type: 'OPEN_MODAL', closeOthers })
  const closeModal = () => dispatch({ type: 'CLOSE_MODAL' })

  const openToast = () => dispatch({ type: 'OPEN_TOAST' })
  const closeToast = () => dispatch({ type: 'CLOSE_TOAST' })

  const setUserAvatar = (value: string) =>
    dispatch({ type: 'SET_USER_AVATAR', value })

  const setDocumentBarTab = (value: DOCUMENT_BAR_TABS) =>
    dispatch({ type: 'SET_DOCUMENT_BAR_TAB', tab: value })

  const setModalView = (
    view: MODAL_VIEWS,
    className: string,
    fill?: boolean,
    props?: any,
    customView?: any,
    style?: any
  ) => {
    dispatch({
      type: 'SET_MODAL_VIEW',
      modalView: { view, className, fill: !!fill, props, customView, style },
    })
  }

  const setContentView = (view: CONTENT_VIEWS) =>
    dispatch({ type: 'SET_CONTENT_VIEW', view })

  const value: State = useMemo(
    () => ({
      ...state,
      openSidebar,
      closeSidebar,
      toggleSidebar,
      toggleFullScreenEditor,
      toggleFullScreenPreview,
      openDocumentBar,
      closeDocumentBar,
      toggleDocumentBar,
      openPageHistoryBar,
      closePageHistoryBar,
      togglePageHistoryBar,
      openHistory,
      closeHistory,
      openEditor,
      closeEditor,
      toggleEditor,
      openPreview,
      closePreview,
      togglePreview,
      enableResponsivePreview,
      disableResponsivePreview,
      enableRenderImmediately,
      disableRenderImmediately,
      toggleRenderImmediately,
      enableAutoFormatMDX,
      disableAutoFormatMDX,
      toggleAutoFormatMDX,
      showBars,
      hideBars,
      openModal,
      closeModal,
      setModalView,
      setContentView,
      openToast,
      closeToast,
      setUserAvatar,
      setDocumentBarTab,
    }),
    [state]
  )

  useEffect(() => {
    const storedIsSidebarVisible = getStoredPref(
      'is_sidebar_visible',
      true,
      isAnonymous
    )
    if (storedIsSidebarVisible !== state.isSidebarVisible) {
      toggleSidebar()
    }

    const storedIsDocumentBarVisible = getStoredPref(
      'is_document_bar_visible',
      false,
      isAnonymous
    )
    if (storedIsDocumentBarVisible !== state.isDocumentBarVisible) {
      if (storedIsDocumentBarVisible) {
        openDocumentBar()
      } else {
        closeDocumentBar()
      }
    }

    const storedIsPageHistoryBarVisible = getStoredPref(
      'is_page_history_bar_visible',
      false,
      isAnonymous
    )
    if (storedIsPageHistoryBarVisible !== state.isPageHistoryBarVisible) {
      if (storedIsPageHistoryBarVisible) {
        openPageHistoryBar()
      } else {
        closePageHistoryBar()
      }
    }

    const storedIsEditorVisible = getStoredPref(
      'is_editor_visible',
      true,
      isAnonymous
    )
    if (storedIsEditorVisible !== state.isEditorVisible) {
      if (storedIsEditorVisible) {
        openEditor()
      } else {
        closeEditor()
      }
    }

    const storedIsPreviewVisible = getStoredPref(
      'is_preview_visible',
      true,
      isAnonymous
    )
    if (storedIsPreviewVisible !== state.isPreviewVisible) {
      if (storedIsPreviewVisible) {
        openPreview()
      } else {
        closePreview()
      }
    }

    const storedIsResponsivePreview = getStoredPref(
      'is_responsive_preview',
      false,
      isAnonymous
    )
    if (storedIsResponsivePreview !== state.isResponsivePreview) {
      if (storedIsResponsivePreview) {
        enableResponsivePreview()
      } else {
        disableResponsivePreview()
      }
    }

    const storedIsRenderImmediately = getStoredPref(
      'is_render_immediately',
      true,
      isAnonymous
    )
    if (storedIsRenderImmediately !== state.isRenderImmediately) {
      if (storedIsRenderImmediately) {
        enableRenderImmediately()
      } else {
        disableRenderImmediately()
      }
    }

    const storedIsAutoFormatMDXEnabled = getStoredPref(
      'is_auto_format_mdx',
      false,
      isAnonymous
    )
    if (storedIsAutoFormatMDXEnabled !== state.isAutoFormatMDXEnabled) {
      if (storedIsAutoFormatMDXEnabled) {
        enableAutoFormatMDX()
      } else {
        disableAutoFormatMDX()
      }
    }
  }, [
    isAnonymous,
    state.isDocumentBarVisible,
    state.isPageHistoryBarVisible,
    state.isEditorVisible,
    state.isPreviewVisible,
    state.isResponsivePreview,
    state.isSidebarVisible,
    state.isRenderImmediately,
    state.isAutoFormatMDXEnabled,
  ])

  return <UIContext.Provider value={value} {...props} />
}

export const useUI = () => {
  const context = useContext(UIContext)
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`)
  }
  return context
}

export const ManagedUIContext = ({ children }: PropsWithChildren) => (
  <UIProvider>{children}</UIProvider>
)
