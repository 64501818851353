import faunadb from 'faunadb'
import { customAlphabet } from 'nanoid'

const DB_ID_LENGTH = 18

const nnanoidWithZero = (length: number): string => {
  return customAlphabet('0123456789', length)()
}

const nnanoidWithoutZero = (length: number): string => {
  return customAlphabet('123456789', length)()
}

export const getFaunaClient = (timeout?: number): faunadb.Client =>
  new faunadb.Client({
    secret: process.env.FAUNADB_MOTIF_KEY || '',
    timeout: timeout || 20,
  })

export const getItemId = (item: any): string => {
  return item.ref['@ref'].id
}

export const getDBItemId = (item: any): string => {
  return getItemId(JSON.parse(JSON.stringify(item)))
}

export const getRefId = (item: any): string => {
  return item['@ref'].id
}

export const getDBRefId = (item: any): string => {
  return getRefId(JSON.parse(JSON.stringify(item)))
}

// DB Ids should NOT start with a 0. Fauna will
// sometimes return the full string with leading 0, sometines
// (e.g. when returning from index queries), it will not.
export const generateDBId = (): string => {
  return nnanoidWithoutZero(1) + nnanoidWithZero(DB_ID_LENGTH - 1)
}

export const removeLeadingZerosFromId = (id: string | undefined) => {
  return id?.replace(/^0+/gi, '')
}
