import { createContainer } from 'unstated-next'

const useIsAnonymous = (initialState: { isAnonymous: boolean } | undefined) => {
  return { isAnonymous: !!initialState?.isAnonymous }
}

const Container = createContainer(useIsAnonymous)
Container.Provider.displayName = 'IsAnonymousProvider'

export default Container
