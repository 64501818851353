import { DefaultSeoProps } from 'next-seo'

const coverUrl =
  'https://res.cloudinary.com/djp21wtxm/image/upload/v1652910263/Card_y7enrc.png'

// NOTE: Adding a `defaultTitle` seems to override `title` values.
const seoConfig: DefaultSeoProps = {
  titleTemplate: '%s – Motif',
  description:
    'The coding environment geared for content. Built on MDX/Markdoc, React and Tailwind CSS.',
  canonical: 'https://motif.land/',
  openGraph: {
    title: 'Motif',
    description:
      'The coding environment geared for content. Built on MDX/Markdoc, React and Tailwind CSS.',
    type: 'website',
    locale: 'en_IE',
    url: 'https://motif.land/',
    site_name: 'Motif',
    images: [
      {
        url: coverUrl,
        width: 1600,
        height: 800,
        alt: 'Motif',
      },
    ],
  },
  twitter: {
    handle: '@motifland',
    site: '@motifland',
    cardType: 'summary_large_image',
  },
  additionalMetaTags: [
    {
      name: 'application-name',
      content: 'Motif',
    },
    {
      name: 'theme-color',
      content: '#0f172a',
    },
    {
      name: 'apple-mobile-web-app-title',
      content: 'Motif',
    },
    {
      name: 'apple-mobile-web-app-status-bar',
      content: '#0f172a',
    },
    {
      name: 'msapplication-TileColor',
      content: '#ffffff',
    },
    {
      name: 'msapplication-config',
      content: '/browserconfig.xml',
    },
  ],
  additionalLinkTags: [
    {
      rel: 'manifest',
      href: '/site.webmanifest',
    },
    {
      rel: 'shortcut icon',
      href: '/favicon.ico',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '/apple-touch-icon.png',
    },
  ],
}

export const publicSeoConfig: DefaultSeoProps = {
  titleTemplate: '%s – Motif',
  defaultTitle: 'The collaborative Markdoc platform',
  description:
    "Motif brings content writers and developers on the same page. Powered by Markdoc, it scales with your team's needs.",
  canonical: 'https://motif.land/',
  twitter: {
    handle: '@motifland',
    site: '@motifland',
    cardType: 'summary_large_image',
  },
  additionalLinkTags: [
    {
      rel: 'manifest',
      href: '/site.webmanifest',
    },
    {
      rel: 'shortcut icon',
      href: '/favicon.ico',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '/apple-touch-icon.png',
    },
  ],
}

export const shouldUseDefaultConfig = (path: string) => {
  if (!path) {
    return false
  }
  return (
    path.startsWith('/files') ||
    path.startsWith('/project') ||
    path.startsWith('/team') ||
    path.startsWith('/community') ||
    path.startsWith('/dashboard')
  )
}

export default seoConfig
